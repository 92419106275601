import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Hero = ({data}) => {

  const renderButton = (button) => {
    if(button.url) { 
      return (<a href={button.url} className={`heroButton${button.appointment ? ' appointment' : ''}`} target="_blank">{button.title}</a>)
    } else {
      return <div className="heroButton">{button.title}</div>
    }
  }

  return (
    <div className="topHero">
      <GatsbyImage 
        image={getImage(data.backgroundImage)} 
        alt={data.title} 
        className="hero-image"
        style={{ position: 'absolute', width: '100%', height: '100%' }}
      />
      <div className="heroContainer">
        {data.subTitle ? <div className="heroSubTitle">{data.subTitle}</div> : ''}
        <div className="heroTitle text-center"><h2>{data.title}</h2></div>
        {data.heroButton && renderButton(data.heroButton)}
      </div>
    </div>
  )
} 

export default Hero

import React from 'react'
import sidebarImg from '../images/sidebar-img.jpg'
import NewsletterForm from './newsletterForm'

const Sidebar = () => {
  return (
    <div className={'sidebar'}>
      <img src={sidebarImg} alt="Ready to perform at your best?" />
      <div className="schedule-appointment">
        <a href={'https://ehr.unifiedpractice.com/Public/OnlineBooking?ClinicUid=ad4fbee7-5eab-4efa-9cfa-8135ba772200'} className={`heroButton appointment`} target="_blank" rel="noopener noreferrer">Schedule an Appointment</a>
      </div>
      <div className="newsletter">
        <h2 className="text-center">Join Our Newsletter</h2>
        <NewsletterForm />
      </div>
    </div>
  )
}

export default Sidebar
